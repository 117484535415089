import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import RightArrow from "@/public/icons/arrow-brown-400.svg";
import routes from "@/constants/routes";

const Custom404 = () => {
  return (
    <>
      <Head>
        <title>Keyzy - 404</title>
      </Head>

      <div className="flex items-center justify-center w-full h-full bg-grey-900">
        <div className="flex flex-col max-w-[31.25rem]">
          <div className="relative w-[18.75rem] h-[20rem] md:w-[31.25rem] md:h-[34.75rem]">
            <Image
              src="/shared/404.svg"
              alt="something weng wrong"
              objectFit="contain"
              layout="fill"
            />
          </div>

          <div className="flex justify-between mt-12 md:mt-16 w-[19.25rem] mx-auto">
            <Link href={routes.home}>
              <a className="flex items-center text-base font-bold md:text-lg text-brown-400">
                Back to website <RightArrow className="ml-3" />
              </a>
            </Link>

            <Link href={routes.dashboard.login}>
              <a className="flex items-center text-base font-bold md:text-lg text-brown-400">
                Login <RightArrow className="ml-3 " />
              </a>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Custom404;
